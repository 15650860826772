import React from 'react';
import { Link } from 'react-router-dom';
import { adminMenu } from '../js/menu';


export default function Overview() {
    return (
        <section className="card overview">
            <div className='box-container'>
                <div className='box apps-box'>
                    <h3 className="box-title">Apps</h3>
                    <div className="box-grid">
                        {adminMenu.map((item, index) => (
                            item.isOnOverview &&
                            item.type === 'app' && (
                                <Link to={item.path} key={index} className="box-item">
                                    <div className="icon-container">{item.icon}</div>
                                    <span to={item.path} className="link-text">
                                        {item.name}
                                    </span>
                                </Link>
                            )
                        ))}
                    </div>
                </div>

                <div className='box customers-box'>
                    <h3 className="box-title">CRM</h3>
                    <div className="box-grid">
                        {adminMenu.map((item, index) => (
                            item.isOnOverview &&
                            item.type === 'crm' && (
                                <Link to={item.path} key={index} className="box-item">
                                    <div className="icon-container">{item.icon}</div>
                                    <span to={item.path} className="link-text">
                                        {item.name}
                                    </span>
                                </Link>
                            )
                        ))}
                    </div>
                </div>
            </div>

            <div className='box-container'>
                <div className='box settings-box'>
                    <h3 className="box-title">Account</h3>
                    <div className="box-grid">
                        {adminMenu.map((item, index) => (
                            item.isOnOverview &&
                            item.type === 'setting' && (
                                <Link to={item.path} key={index} className="box-item">
                                    <div className="icon-container">{item.icon}</div>
                                    <span to={item.path} className="link-text">
                                        {item.name}
                                    </span>
                                </Link>
                            )
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
