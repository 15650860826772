import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../img/logo200.png';
import { FaMobileAlt as MobileIcon } from "react-icons/fa";
import { nav1 } from '../../../js/translation';

export default function Navigation1({ language, isLoginBtnHidden }) {
  return (
    <nav className='navigation nav1'>
      <ul>
        <li>
          <Link className='menu-link' to='/'>
            <img className='logo' src={logo} alt="Logo" />
          </Link>
        </li>
        <li>
          <Link className='menu-link' to='/about'>
            {nav1.about[language]}
          </Link>
        </li>
        <li>
          <Link className='menu-link' to='/plans-and-pricing'>
            {nav1.pricing[language]}
          </Link>
        </li>
        <li>
          <Link className='menu-link' to='/contact'>
            {nav1.contact[language]}
          </Link>
        </li>
        <li className='to-right'>
          <a className='menu-link' href='tel:+447421411763'>
            <MobileIcon className="icon" /> 07 421 411 763
          </a>
        </li>
        <li>
          <Link className='menu-link' to='/support'>
            {nav1.support[language]}
          </Link>
        </li>
        <li className={isLoginBtnHidden ? 'hidden-item' : 'visible-item'}>
          <Link to='/login'>
            <button className='btn framed-btn'>
              {nav1.login[language]}
            </button>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
