const translation = {
    banner: {
        en: <>Your Partner in streamlining success</>,
        hu: <>Partnere a siker kifinomult megvalósításában</>,
    },
    nav1: {
        about: {
            en: <>About</>,
            hu: <>Rólunk</>
        },
        pricing: {
            en: <>Plans & Pricing</>,
            hu: <>Árak</>
        },
        contact: {
            en: <>Contact</>,
            hu: <>Kapcsolat</>
        },
        support: {
            en: <>Support</>,
            hu: <>Ügyfélszolgálat</>
        },
        login: {
            en: <>Sign in</>,
            hu: <>Bejelentkezés</>
        }
    },
    nav2: {
        companies: {
            en: <>For Companies</>,
            hu: <>Cégeknek</>
        },
        clients: {
            en: <>For Clients</>,
            hu: <>Magánszemélyeknek</>
        },
        partners: {
            en: <>For Partners</>,
            hu: <>Partnereknek</>
        },
    },
    footer: {
        privacy: {
            en: <>Privacy</>,
            hu: <>Adatvédelem</>
        },
        terms: {
            en: <>Terms & Conditions</>,
            hu: <>Felhasználási feltételek</>
        },
        contact: {
            en: <>Contact</>,
            hu: <>Kapcsolat</>
        },
        language: {
            en: <>Language</>,
            hu: <>Nyelv</>
        },
        copyright: {
            en: <>CRM Hub 365. All rights reserved.</>,
            hu: <>CRM Hub 365. Minden jog fenntartva.</>
        }
    },
    sections: {
        hero: {
            p1: {
                en: <>Your All-in-One Business Solution</>,
                hu: <>Mindent egyben, mindent egyhelyen</>,
            },
            h1: {
                en: <>CRM Hub 365</>,
                hu: <>CRM Hub 365</>,
            },
            h2: {
                en: <>Empowering Businesses Beyond CRM</>,
                hu: <>Több, mint egy egyszerű CRM</>,
            },
            p2: {
                en: <>Streamline your processes, enhance customer relationships, and unlock growth opportunities — all in one powerful platform.</>,
                hu: <>Egyszerűsítse folyamatait, javítsa az ügyfélkapcsolatokat és nyisson meg új lehetőségeket — mindezt egyetlen platformon.</>,
            },
            button: {
                en: <>View Plans & Pricing</>,
                hu: <>Árak megtekintése</>,
            }
        },
        solution: {
            tailored: {
                en: <>Tailored for Business Success</>,
                hu: <>Üzleti sikerhez igazítva</>,
            },
            tailoredDesc: {
                en: <>Discover a unified platform designed to optimize your business operations and drive growth.</>,
                hu: <>Fedezzen fel egy egységes platformot, amely az üzleti működés optimalizálására és növekedésére lett tervezve.</>,
            },
            documentation: {
                en: <>Effortless Documentation Management</>,
                hu: <>Kezelje dokumentumait hatékonyan</>,
            },
            documentationDesc: {
                en: <>Simplify your document creation, storage, and organization all in one place.</>,
                hu: <>Egyszerűsítse a dokumentumok létrehozását, tárolását és rendszerezését egy helyen.</>,
            },
            invoicing: {
                en: <>Invoicing Made Easy</>,
                hu: <>Gyors és egyszerű számlázás</>,
            },
            invoicingDesc: {
                en: <>Create and manage professional invoices with ease, ensuring accuracy and efficiency.</>,
                hu: <>Készítsen és kezeljen professzionális számlákat egyszerűen, biztosítva a pontosságot és hatékonyságot.</>,
            },
            power: {
                en: <>Unleash the Power of CRM Hub 365</>,
                hu: <>Szabadítsa fel a CRM Hub 365 erejét</>,
            },
            powerDesc: {
                en: <>Empower your business with a comprehensive CRM solution to manage and grow your customer relationships.</>,
                hu: <>Támogassa vállalkozását egy átfogó CRM megoldással az ügyfélkapcsolatok kezelésére és növelésére.</>,
            },
            viewPlans: {
                en: <>View Plans & Pricing</>,
                hu: <>Árak megtekintése</>,
            },
            track: {
                en: <>Track Financials with Confidence</>,
                hu: <>Kövesse nyomon pénzügyeit</>,
            },
            trackDesc: {
                en: <>Monitor cash flow and financial performance with intuitive tools for better decision-making.</>,
                hu: <>Figyelje a pénzforgalmat és a pénzügyi teljesítményt intuitív eszközökkel a jobb döntéshozatal érdekében.</>,
            },
            customer: {
                en: <>Seamless Customer Relationship Management</>,
                hu: <>Kezelje ügyfeleit zökkenőmentesen</>,
            },
            customerDesc: {
                en: <>Maintain and enhance customer satisfaction with tools that keep you connected and informed.</>,
                hu: <>Tartsa fenn és javítsa az ügyfél-elégedettséget olyan eszközökkel, amelyek kapcsolatban és informáltan tartják.</>,
            },
            findOutMore: {
                en: <>Find out more</>,
                hu: <>Tudjon meg többet</>,
            },
        }

    }
};

export const nav1 = translation.nav1;
export const nav2 = translation.nav2;

export const banner = translation.banner;
export const copyright = translation.footer.copyright;

export const footer = translation.footer;

export const hero = translation.sections.hero;
export const solution = translation.sections.solution;
