import React from 'react';
import Copyright from '../global-components/Copyright';
import Navigation6 from '../global-components/navigations/Navigation6';

export default function AdminFooter({ language }) {
    return (
        <footer>
            {false && <Navigation6 language={language} />}
            <Copyright language={language} />
        </footer>
    )
}
