import React from 'react';

export default function Help({ language }) {
    return (
        <div className='content'>
            <div className='card'>
                <h2>Help card</h2>
            </div>
        </div>
    )
}



