import React from 'react';
import Navigation3 from '../global-components/navigations/Navigation3';
import Copyright from '../global-components/Copyright';

export default function WebsiteFooter({ language, setLanguage }) {
    return (
        <footer>
            <Navigation3 language={language} setLanguage={setLanguage} />
            <Copyright language={language} />
        </footer>
    )
}
