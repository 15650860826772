import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function TextEditor() { // Renamed to TextEditor (matching the error)
    const [content, setContent] = useState('');

    const handleChange = (value) => {
        setContent(value);
        // Remove the 'onContentChange' since it's not defined in this component
    };

    return (
        <div className="text-editor">
            <ReactQuill
                value={content}
                onChange={handleChange}
                theme="snow"
                placeholder="Enter your message here..."
            />
        </div>
    );
}
