import { FaFileInvoice as InvoiceIcon } from "react-icons/fa";
import { FaMailBulk as NewsIcon } from "react-icons/fa";
import { IoShieldCheckmarkSharp as ServicesIcon } from "react-icons/io5";
import { FaUserCircle as ProfileIcon } from "react-icons/fa";
import { IoSettingsSharp as SettingsIcon } from "react-icons/io5";
import { MdDashboard as DashboardIcon } from "react-icons/md";
import { IoHelpCircleSharp as HelpIcon } from "react-icons/io5";
import { IoPeople as CustomersIcon } from "react-icons/io5";

const menu = {
    admin: [
        {
            name: 'Dashboard',
            icon: <DashboardIcon className="icon" />,
            isDisplayedInNav: !false,
            isOnOverview: false,
            type: 'app',
            path: '/admin/dashboard',
        },
        {
            name: 'CommuniCast',
            icon: <NewsIcon className="icon" />,
            isDisplayedInNav: true,
            isOnOverview: true,
            type: 'app',
            path: '/admin/communicast',
        },
        {
            name: 'Users',
            isDisplayedInNav: false,
            isOnOverview: false,
            type: 'app',
            path: '/admin/users',
        },
        {
            name: 'InvoiceSnap',
            icon: <InvoiceIcon className="icon" />,
            isDisplayedInNav: true,
            isOnOverview: true,
            type: 'app',
            path: '/admin/invoicesnap',
        },
        {
            name: 'Customers',
            icon: <CustomersIcon className="icon" />,
            isDisplayedInNav: false,
            isOnOverview: true,
            type: 'crm',
            path: '/admin/customers',
        },
        {
            name: 'Services',
            icon: <ServicesIcon className="icon" />,
            isDisplayedInNav: false,
            isOnOverview: true,
            type: 'crm',
            path: '/admin/services',
        },
        {
            name: 'Profile',
            icon: <ProfileIcon className="icon" />,
            isDisplayedInNav: true,
            isOnOverview: true,
            type: 'setting',
            path: '/admin/profile',
        },
        {
            name: 'Settings',
            icon: <SettingsIcon className="icon" />,
            isDisplayedInNav: true,
            isOnOverview: true,
            type: 'setting',
            path: '/admin/settings',
        },
        {
            name: 'Help',
            icon: <HelpIcon className="icon" />,
            isDisplayedInNav: true,
            isOnOverview: true,
            type: 'setting',
            path: '/admin/help',
        },
    ],
    website: [
        {
            name: 'home',
            isLoginBtnHidden: false
        },
        {
            name: 'about',
            isLoginBtnHidden: false
        },
        {
            name: 'plans-and-pricing',
            isLoginBtnHidden: false
        },
        {
            name: 'contact',
            isLoginBtnHidden: false
        },
        {
            name: 'support',
            isLoginBtnHidden: false
        },
        {
            name: 'privacy',
            isLoginBtnHidden: false
        },
        {
            name: 'terms-and-conditions',
            isLoginBtnHidden: false
        },
        {
            name: 'login',
            isLoginBtnHidden: true
        },
        {
            name: 'registration',
            isLoginBtnHidden: false
        }
    ]
};

export const adminMenu = menu.admin;
export const websiteMenu = menu.website;
