import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Breadcrumbs() {
    const location = useLocation();

    const [breadcrumbs, setBreadcrumbs] = useState("");

    useEffect(() => {
        const path = location.pathname
            .split('/')
            .join(' / ');

        setBreadcrumbs(path);
    }, [location.pathname]);

    return (
        <div className='breadcrumbs'>{breadcrumbs}</div>
    )
}
