import React, { useState, useEffect } from 'react';

export default function Notifications() {
    const [isNewsletter, setIsNewsletter] = useState(true);
    const [isEmailNotification, setIsEmailNotification] = useState(true);

    const handleNewsletterChange = (e) => {
        setIsNewsletter(!isNewsletter);
        // Save !isNewsletter to database
    };

    const handleEmailNotificationChange = (e) => {
        setIsEmailNotification(!isEmailNotification);
        // Save !isNewsletter to database
    };


    return (
        <section className="card ">
            <div className='card-header'>
                <h2>Notifications</h2>
            </div>

            <div className='card-body'>
                <div className="notification-settings">
                    <label>Email notifications</label>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={isEmailNotification} onChange={handleEmailNotificationChange} />
                        <span className="slider"></span>
                    </label>
                </div>

                <div className="notification-settings">
                    <label>Send me newsletters</label>
                    <label className="toggle-switch">
                        <input type="checkbox" checked={isNewsletter} onChange={handleNewsletterChange} />
                        <span className="slider"></span>
                    </label>
                </div>
            </div>
        </section>
    );
}
