import React from 'react';
import { Link } from 'react-router-dom';
import { solution } from '../../../js/translation';

export default function Solutions({ language }) {
  return (
    <section className="section-container solutions">

      <Link to='success-hub' className="grid-item item-1">
        <div className='grid-content'>
          <h2>{solution.tailored[language]}</h2>
          <p className='description'>{solution.tailoredDesc[language]}</p>
          <p className='details'>{solution.findOutMore[language]}</p>
        </div>
      </Link>

      <Link to='invoicing-hub' className="grid-item item-3">
        <div className='grid-content'>
          <h2>{solution.invoicing[language]}</h2>
          <p className='description'>{solution.invoicingDesc[language]}</p>
          <p className='details'>{solution.findOutMore[language]}</p>
        </div>
      </Link>

      <Link to='documentation-hub' className="grid-item item-2">
        <div className='grid-content'>
          <h2>{solution.documentation[language]}</h2>
          <p className='description'>{solution.documentationDesc[language]}</p>
          <p className='details'>{solution.findOutMore[language]}</p>
        </div>
      </Link>

      <div className="middle-row">
        <Link to='power-hub' className="grid-item item-4">
          <div className='grid-content'>
            <h2>{solution.power[language]}</h2>
            <p className='description'>{solution.powerDesc[language]}</p>
            <button className='btn marketing-btn'>{solution.viewPlans[language]}</button>
          </div>
        </Link>

        <div className="comparison-column">
          <Link to='track-hub' className="grid-item item-5">
            <div className='grid-content'>
              <h2>{solution.track[language]}</h2>
              <p className='description'>{solution.trackDesc[language]}</p>
            </div>
          </Link>

          <Link to='customer-hub' className="grid-item item-6">
            <div className='grid-content'>
              <h2>{solution.customer[language]}</h2>
              <p className='description'>{solution.customerDesc[language]}</p>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}
