import React, { useState } from 'react';
import ManageServices from '../cards/ManageServices';
import AddNewServiceModal from '../modals/AddNewServiceModal';

export default function Services({ language }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: adds smooth scrolling animation
        });
    }

    return (
        <div className='content'>
            <ManageServices toggleModal={toggleModal} />
            <AddNewServiceModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
            />

        </div>
    )
}



