import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { adminMenu } from '../../admin/js/menu';
import logo from '../../../img/logoDash.png';

export default function AdminSideNav({ isMenuOpen }) {
    const [activeMenu, setActiveMenu] = useState("");
    const [navWidth, setNavWidth] = useState(0);

    const pageName = useLocation().pathname.split('/').at(-1);

    useEffect(() => {
        let currentNavWidth = document.querySelector('.side-nav-admin').clientWidth;
        setNavWidth(currentNavWidth);
        setActiveMenu(pageName);
    }, []);

    return (
        <nav
            className="navigation side-nav side-nav-admin"
            style={isMenuOpen ? { marginLeft: '0px' } : { marginLeft: `-${navWidth - 10}px` }}
        >
            <div className='nav-head'>
                <h2 className="brand">
                    <Link to='/'>
                        <img src={logo} alt="logo" className='logo' />
                        {/*CRM Hub 365*/}
                    </Link>
                </h2>
            </div>

            {adminMenu.map((item, index) => (
                item.isDisplayedInNav &&
                <Link
                    key={index}
                    to={item.path}
                    className={pageName === item.name.toLowerCase() ? 'nav-link active-link' : 'nav-link'}
                >
                    {item.name}
                    {item.icon}
                </Link>
            ))}
        </nav>
    )
}
