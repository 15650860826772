import React from 'react';

import subscribers from '../js/db_subscribers';
import ActionButton from '../../global-components/buttons/ActionButton';
import TableFilter from '../card-components/TableFilter';

import { HiMiniChevronUpDown as UpDownIcon } from "react-icons/hi2";

export default function ManageSubscribers() {

    const filteredClients = subscribers;

    return (
        <section id='manage-subsribers' className="card admin-card">

            <div className='card-header'>
                <h2>Manage subscribers</h2>
                <TableFilter isAddIconDisplayed={true} isImportIconDisplayed={true} />
            </div>

            <div className='card-body'>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        <input type='checkbox' />
                                        Email <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Name <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Groups <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClients.length > 0 ? (
                                filteredClients.map((client, index) => (
                                    <tr key={index}>
                                        <td><span><input type='checkbox' /> {client.email}</span></td>
                                        <td>{client.name}</td>
                                        <td>{client.groups.join(", ")}</td>
                                        <td className='action-cell'>
                                            <ActionButton btnType="edit" />
                                            <ActionButton btnType="delete" />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}
