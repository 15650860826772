import React from 'react';
import { nav2 } from '../../../js/translation';

export default function Navigation2({ language }) {
    return (
        <nav className='navigation nav2'>
            <ul>
                <button className='btn active-framed-btn'>{nav2.companies[language]}</button>
                <button className='btn framed-btn'>{nav2.clients[language]}</button>
                <button className='btn framed-btn'>{nav2.partners[language]}</button>
            </ul>
        </nav>
    )
}
