import React, { useState } from 'react';
import admin from '../js/db_admin';
import ActionButton from '../../global-components/buttons/ActionButton';

export default function Password() {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Handlers for controlled inputs
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    // Form submission handler with validation
    const handleSubmit = (e) => {
        e.preventDefault();

        // Ensure the new password and confirmation match
        if (newPassword !== confirmPassword) {
            alert("A megadott jelszavak nem egyeznek!");
            return;
        }

        // Ensure all fields are filled
        if (!currentPassword || !newPassword || !confirmPassword) {
            alert("Kérjük, töltse ki az összes mezőt!");
            return;
        }

        // Here, you would send the new password to the backend for updating
        alert("Jelszó sikeresen frissítve!");

        // Clear input fields after saving
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
    };

    return (
        <section className="card">
            <div className='card-header'>
                <h2>Password</h2>
            </div>

            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Password *</label>
                        <input
                            type="password"
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>New password *</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Confirm new password *</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                    </div>

                    <ActionButton text="Save" btnType="save" />
                </form>
            </div>
        </section>
    );
}
