import React, { useState } from 'react';
import WebsiteHeader from './website/WebsiteHeader';
import WebsiteMain from './website/WebsiteMain';
import WebsiteFooter from './website/WebsiteFooter';
import AdminHeader from './admin/AdminHeader';
import AdminMain from './admin/AdminMain';
import AdminFooter from './admin/AdminFooter';

export default function PageLayout({ app, page, isLoginBtnHidden }) {
    const [language, setLanguage] = useState('en');
    const [loginAs, setLoginAs] = useState('user');

    const apps = {
        website: {
            header: <WebsiteHeader language={language} isLoginBtnHidden={isLoginBtnHidden} />,
            main: <WebsiteMain page={page} language={language} loginAs={loginAs} setLoginAs={setLoginAs} />,
            footer: <WebsiteFooter language={language} setLanguage={setLanguage} />
        },
        admin: {
            header: <AdminHeader language={language} />,
            main: <AdminMain page={page} language={language} />,
            footer: <AdminFooter language={language} />
        }
    };

    return (
        <div className='page-layout'>
            {apps[app].header}
            {apps[app].main}
            {apps[app].footer}
        </div>
    )
}
