import React from 'react';
import { Link } from 'react-router-dom';
import { FaAlignLeft as HamburgerIcon } from "react-icons/fa";
import { IoIosHelpCircleOutline as HelpIcon } from "react-icons/io";
import { GoGear as SettingsIcon } from "react-icons/go";
import { MdDashboard as HomeIcon } from "react-icons/md";

export default function Navigation7({ language, isMenuOpen, toggleMenu }) {

  return (
    <nav className='navigation nav7'>
      <ul>
        <li>
          <button onClick={toggleMenu}>
            <HamburgerIcon className='icon hamburger-icon' style={isMenuOpen ? {} : { transform: 'rotateY(180deg)' }} />
          </button>
        </li>
        <li className='signed-in-name'>
          <Link to='/admin/dashboard'>
            <button >
              <HomeIcon className='icon home-icon' />
              Dashboard
            </button>
          </Link>
        </li>
        <li className="to-right">
          <Link to='/admin/help'>
            <button>
              <HelpIcon className='icon' />
              <span>
                Help
              </span>
            </button>
          </Link>
        </li>
        <li>
          <Link to='/admin/settings'>
            <button>
              <SettingsIcon className='icon' />
            </button>
          </Link>
        </li>
        <li>
          <Link to='/admin/profile'>
            <button className="profile-btn">
              <span className="icon ">T</span>
            </button>
          </Link>
        </li>
      </ul>
    </nav>
  )
}
