import React, { useState, useEffect } from 'react';

import admin from '../js/db_admin';

import ActionButton from '../../global-components/buttons/ActionButton';

const pass = "alma";

export default function Email() {
    const [email, setEmail] = useState(admin.adataim.email);
    const [emailInputValue, setEmailInputValue] = useState("");
    const [passValue, setPassValue] = useState("");

    const handleEmailInputChange = (e) => {
        setEmailInputValue(e.target.value);
    };

    const handlePassValueChange = (e) => {
        setPassValue(e.target.value);
    };

    const handleSubmit = (e,) => {
        e.preventDefault();

        if (passValue === pass) {
            const sanitizedEmail = emailInputValue.trim().replace(/\s+/g, ' ').toLocaleLowerCase();

            if (sanitizedEmail) {
                setEmail(sanitizedEmail);
                setEmailInputValue('');
            }

            setPassValue("");
        } else {
            alert("Hibás jelszó");
        }
    };


    return (
        <section className="card ">
            <div className='card-header'>
                <h2>Email</h2>
            </div>

            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <span>{email}</span>
                    </div>

                    <div className="form-group">
                        <label>New email *</label>
                        <input type="email" value={emailInputValue} onChange={(e) => handleEmailInputChange(e)} />
                    </div>

                    <div className="form-group">
                        <label>Password *</label>
                        <input placeholder={pass} value={passValue} type="password" onChange={(e) => handlePassValueChange(e)} />
                    </div>

                    <ActionButton text="Save" btnType="save" />
                </form>
            </div>
        </section>
    );
}
