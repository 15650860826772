import React, { useState, useEffect } from 'react';
import { MdContentCopy as CopyIcon } from "react-icons/md";
import { FaCheck as OkIcon } from "react-icons/fa6";
import admin from '../js/db_admin';

export default function AdminID() {
    const [copied, setCopied] = useState(false);

    const copyCode = () => {
        const textToCopy = admin.adataim.azonosito.toLocaleUpperCase();
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                // Revert back to CopyIcon after 5 seconds
                setTimeout(() => setCopied(false), 5000);
            })
            .catch(err => {
                alert("Failed to copy text: ", err);
            });
    };

    return (
        <section className="card">
            <div className='card-header'>
                <h2>Admin ID</h2>
            </div>

            <div className='card-body'>
                <form action="">
                    <div className="form-group">
                        <div className='admin-code'>
                            {admin.adataim.azonosito.split('').map((char, index) => (
                                <span key={index}>{char}</span>
                            ))}
                            {copied ? (
                                <OkIcon title='Copyed to clipboard!' className='icon' />
                            ) : (
                                <CopyIcon title='Copy to clipboard' className='icon' onClick={copyCode} />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
