import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './styles/App.css';
import PageLayout from './components/PageLayout';

import {adminMenu, websiteMenu} from './components/admin/js/menu';

export default function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route
            path='*'
            element={<h1>Page not found</h1>}>  
          </Route>

          {/* Website app Routes */}
          {websiteMenu.map((menu, index) => 
              <Route 
                key={index} 
                path={menu.name === 'home' ? '/' : `/${menu.name.toLowerCase()}`}
                element={<PageLayout app='website' page={menu.name.toLowerCase()} />}>
              </Route>
            )}

          {/* Admin app Routes */}
          {adminMenu.map((menu, index) => 
              <Route
                key={index}
                path={`admin/${menu.name.toLowerCase()}`}
                element={<PageLayout app='admin' page={menu.name.toLowerCase()} />}>
              </Route>
            )}
        </Routes>
      </div>
    </Router>
  )
}
