import React, { useState } from 'react';

import prices from '../js/db_prices';

import ActionButton from '../../global-components/buttons/ActionButton';
import TableFilter from '../card-components/TableFilter';

import { HiMiniChevronUpDown as UpDownIcon } from "react-icons/hi2";
import { IoShieldCheckmarkSharp as ServicesIcon } from "react-icons/io5";

export default function ManageServices({ toggleModal }) {
    // State variables for filters
    const [searchTerm, setSearchTerm] = useState('');
    const [descriptionSearchTerm, setDescriptionSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [statusFilter, setStatusFilter] = useState('active'); // 'active' is selected by default

    // Get unique categories from the services data
    const categories = [...new Set(prices.map(service => service.category))];

    // Handle category change from the dropdown
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    // Function to reset all filters
    const resetFilters = () => {
        setSearchTerm('');
        setDescriptionSearchTerm('');
        setSelectedCategory('');
        setStatusFilter('active');
    };

    // Filter the services based on search term, description, category, and status
    const filteredServices = prices.filter(service => {
        const matchesName = searchTerm === '' || service.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesDescription = descriptionSearchTerm === '' || service.description.toLowerCase().includes(descriptionSearchTerm.toLowerCase());
        const matchesCategory = selectedCategory === '' || service.category === selectedCategory;
        const matchesStatus = statusFilter === 'active' ? service.isActive : !service.isActive;

        return matchesName && matchesDescription && matchesCategory && matchesStatus;
    });

    return (
        <section className="card admin-card">
            <div className='card-header'>
                <h2>
                    <ServicesIcon className='icon' />
                    Manage services
                </h2>
                <TableFilter isAddIconDisplayed={true} isImportIconDisplayed={true} toggleModal={toggleModal} />
            </div>

            <div className='card-body'>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th><span><input type='checkbox' /> Name <UpDownIcon className='icon' /></span></th>
                                <th><span>Description <UpDownIcon className='icon' /></span></th>
                                <th><span>Categories <UpDownIcon className='icon' /></span></th>
                                <th><span>Price <UpDownIcon className='icon' /></span></th>
                                <th><span>Active <UpDownIcon className='icon' /></span></th>
                                <th><span> </span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredServices.length > 0 ? (
                                filteredServices.map((service, index) => (
                                    <tr key={index}>
                                        <td title={service.name}>
                                            <span>
                                                <input type='checkbox' />
                                                {service.name.length > 20 ? service.name.slice(0, 19) + "..." : service.name}
                                            </span>
                                        </td>
                                        <td title={service.description}>
                                            {service.description.length > 20 ? service.description.slice(0, 19) + "..." : service.description}
                                        </td>
                                        <td>{service.category}</td>
                                        <td>{new Intl.NumberFormat('en-US', { useGrouping: true }).format(service.price).replace(/,/g, ' ')}</td>
                                        <td>{service.isActive ? 'Igen' : 'Nem'}</td>
                                        <td className='action-cell'>
                                            <ActionButton btnType="edit" />
                                            <ActionButton btnType="delete" />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}
