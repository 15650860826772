import React from 'react';

import customers from '../js/db_customers';
import ActionButton from '../../global-components/buttons/ActionButton';
import TableFilter from '../card-components/TableFilter';

import { HiMiniChevronUpDown as UpDownIcon } from "react-icons/hi2";
import { IoPeople as CustomersIcon } from "react-icons/io5";

export default function ManageCustomers({ toggleModal }) {

    const filteredList = customers;

    return (
        <section className="card admin-card">
            <div className='card-header'>
                <h2>
                    <CustomersIcon className='icon' />
                    Manage customers
                </h2>
                <TableFilter isAddIconDisplayed={true} isImportIconDisplayed={true} toggleModal={toggleModal} />
            </div>

            <div className='card-body'>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        <input type='checkbox' />
                                        Name <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Email <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th><span></span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.length > 0 ? (
                                filteredList.map((customer, index) => (
                                    <tr key={index}>
                                        <td><span><input type='checkbox' /> {customer.name}</span></td>
                                        <td>{customer.email}</td>
                                        <td className='action-cell'>
                                            <ActionButton btnType="edit" />
                                            <ActionButton btnType="delete" />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </section>
    )
}
