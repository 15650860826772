import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import AuthButton from '../../global-components/buttons/AuthButton';

export default function Login({ language, loginAs, setLoginAs }) {

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <HelmetProvider>
            <div className="content log-in-out-page">
                <Helmet>
                    <title>Sign in</title>
                </Helmet>

                <div className='wrapper'>
                    <div className="options">
                        <h2>I wish to sign in as:</h2>

                        <label htmlFor="userRole">
                            <input
                                type="radio"
                                id="userRole"
                                name="role"
                                value="user"
                                checked={loginAs === 'user'}
                                onChange={() => setLoginAs('user')}
                            />
                            <span>User</span>
                        </label>

                        <label htmlFor="adminRole">
                            <input
                                type="radio"
                                id="adminRole"
                                name="role"
                                value="admin"
                                checked={loginAs === 'admin'}
                                onChange={() => setLoginAs('admin')}
                            />
                            <span>Admin</span>
                        </label>
                    </div>

                    <div className="panel">
                        <h2>Sign in</h2>

                        <form onSubmit={handleSubmit} method="post">
                            <div className="form-group">
                                <label htmlFor="email">Email or Phone Number:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email or phone number"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password:</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    required
                                />
                            </div>

                            <div className="form-footer">
                                <AuthButton text="Sign in" btnType="login" loginAs={loginAs} />
                                <p>
                                    Don't have an account?{' '}
                                    <Link to="/registration" className="if-link">
                                        Register here
                                    </Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
}
