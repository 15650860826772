import React from 'react';
import { Link } from 'react-router-dom';
import { footer } from '../../../js/translation';
import { IoLanguageSharp as LangIcon } from "react-icons/io5";

export default function Navigation3({ language, setLanguage }) {
  return (
    <nav className='navigation nav3'>
      <ul>
        <li>
          <Link to='/privacy'>
            {footer.privacy[language]}
          </Link>
        </li>
        <li>
          <Link to='/terms-and-conditions'>
            {footer.terms[language]}
          </Link>
        </li>
        <li>
          <Link to='/contact'>
            {footer.contact[language]}
          </Link>
        </li>
        <li className='to-right language-item'>
          <span>
            <LangIcon className='icon language-icon' />
          </span>
          <select className='language-select' value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value='en'>EN</option>
            <option value='hu'>HU</option>
          </select>
        </li>
      </ul>
    </nav>
  )
}
