import React from 'react';
import Banner from '../global-components/Banner';
import Navigation1 from '../global-components/navigations/Navigation1';

export default function WebsiteHeader({ language, isLoginBtnHidden }) {
    return (
        <header>
            <Banner language={language} />
            <Navigation1 language={language} isLoginBtnHidden={isLoginBtnHidden} />
        </header>
    )
}
