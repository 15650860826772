import React from 'react';
import AdminID from '../cards/AdminID';
import Name from '../cards/Name';
import Email from '../cards/Email';
import PhoneNumer from '../cards/PhoneNumer';
import BillingDetails from '../cards/BillingDetails';

export default function Profile({ language }) {
    return (
        <div className='content'>
            <AdminID />
            <Name />
            <Email />
            <PhoneNumer />
            <BillingDetails />
        </div>
    )
}



