import React from 'react';

// Section imports
import Hero from '../sections/Hero';
import Solutions from '../sections/Solutions';

// Navigation imports
import Navigation2 from '../../global-components/navigations/Navigation2';

export default function Home({ language }) {
    return (
        <div className='content'>
            <Hero language={language} />

            <Navigation2 language={language} />

            <Solutions language={language} />
        </div>
    )
}



