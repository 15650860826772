import React from 'react';
import MakeNewInvoice from '../cards/MakeNewInvoice';
import ArchiveInvoices from '../cards/ArchiveInvoices';

export default function InvoiceSnap({ language }) {
    return (
        <div className='content'>
            <MakeNewInvoice />
            <ArchiveInvoices />
        </div>
    )
}



