import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActionButton from '../../global-components/buttons/ActionButton';
import TableFilter from '../card-components/TableFilter';

import { HiMiniChevronUpDown as UpDownIcon } from "react-icons/hi2";

// Mock data for archived newsletters
const newsletters = [
    { date: '2024-11-10', subject: 'November News' },
    { date: '2024-10-15', subject: 'October Update' },
    { date: '2024-09-20', subject: 'Back to School' },
    { date: '2024-08-01', subject: 'Summer Highlights' },
];

export default function ArchiveNewsletters() {
    const [searchParams] = useSearchParams();
    // State variables for filters
    const [dateFilter, setDateFilter] = useState('');
    const [subjectFilter, setSubjectFilter] = useState('');

    // Filter the newsletters based on the filters
    const filteredNewsletters = newsletters.filter(newsletter => {
        const matchesDate = dateFilter === '' || newsletter.date.includes(dateFilter);
        const matchesSubject = subjectFilter === '' || newsletter.subject.toLowerCase().includes(subjectFilter.toLowerCase());

        return matchesDate && matchesSubject;
    });

    // Function to reset all filters
    const resetFilters = () => {
        setDateFilter('');
        setSubjectFilter('');
    };

    useEffect(() => {
        const section = searchParams.get('section');
        if (section) {
            const element = document.getElementById(section);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [searchParams]);

    return (
        <section id='archive-newsletters' className="card">

            <div className='card-header'>
                <h2>Archive newsletters</h2>
                <TableFilter />
            </div>

            <div className='card-body'>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        <input type='checkbox' />
                                        Data <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Subject <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredNewsletters.length > 0 ? (
                                filteredNewsletters.map((newsletter, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span>
                                                <input type='checkbox' />
                                                {newsletter.date}
                                            </span>
                                        </td>
                                        <td>{newsletter.subject}</td>
                                        <td className='action-cell'>
                                            <ActionButton btnType="edit" />
                                            <ActionButton btnType="delete" />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}
