import React, { useState } from 'react';
import ManageCustomers from '../cards/ManageCustomers';
import AddNewCustomerModal from '../modals/AddNewCustomerModal';

export default function Customers({ language }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <div className='content'>
            <ManageCustomers toggleModal={toggleModal} />
            <AddNewCustomerModal
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
            />
        </div>
    )
}



