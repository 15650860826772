import React from 'react';
import { IoClose as CloseIcon } from "react-icons/io5";
import ActionButton from '../../global-components/buttons/ActionButton';

export default function AddNewServiceModal({ isModalOpen, toggleModal }) {
    return (
        <div className='modal add-new-service-modal' style={isModalOpen ? { visibility: 'unset' } : { visibility: 'hidden' }}>
            <div className='wrapper' style={isModalOpen ? { marginRight: '0px' } : { marginRight: '-800px' }}>
                <div className='modal-header'>
                    Modal header - Service
                    <CloseIcon className='icon close-icon' onClick={toggleModal} />
                </div>
                <div className='modal-body'>
                    <div className='modal-body-content'>
                        modal-body-content
                    </div>
                </div>
                <div className='modal-footer'>
                    <ActionButton text="Save" btnType="save" />
                </div>
            </div>
        </div>
    )
}
