import React from 'react';
import Notifications from '../cards/Notifications';
import Password from '../cards/Password';
import AccountLanguage from '../cards/AccountLanguage';

export default function Settings({ language }) {
    return (
        <div className='content'>
            <AccountLanguage />
            <Notifications />
            <Password />
        </div>
    )
}



