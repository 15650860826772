import React, { useRef } from 'react';
import { HiOutlinePrinter as PrinterIcon } from "react-icons/hi2";
import { MdOutlineFileDownload as DownloadIcon } from "react-icons/md";
import { GoGear as SettingsIcon } from "react-icons/go";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";
import { GoPlus as PlusIcon } from "react-icons/go";
import { FaFileImport as ImportIcon } from "react-icons/fa";

export default function TableFilter({ isAddIconDisplayed, isImportIconDisplayed, toggleModal }) {
    const searchRef = useRef(null);

    const handleSearchIconClick = () => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    };

    return (
        <div className='table-filter'>
            <input ref={searchRef} type="text" className='search-input' placeholder='Search' />
            <SearchIcon className='icon search-icon' onClick={handleSearchIconClick} />
            <ul className='icon-set to-right'>
                <li>
                    <button className='btn bold-btn'>
                        <PrinterIcon className='icon' />
                    </button>
                </li>
                <li>
                    <button className='btn bold-btn'>
                        <DownloadIcon className='icon' />
                    </button>
                </li>
                <li>
                    <button className='btn bold-btn'>
                        <SettingsIcon className='icon' />
                    </button>
                </li>
                {isAddIconDisplayed && <li>
                    <button className='btn bold-btn' onClick={toggleModal}>
                        <PlusIcon className='icon' />
                    </button>
                </li>}
                {isImportIconDisplayed && <li>
                    <button className='btn bold-btn'>
                        <ImportIcon className='icon import-icon' />
                    </button>
                </li>}
            </ul>
        </div>
    )
}
