import React from 'react';
import Banner from '../global-components/Banner';
import Navigation5 from '../global-components/navigations/Navigation5';

export default function AdminHeader({ language }) {
    return (
        <header>
            {true && <Banner language={language} />}
            {false && <Navigation5 language={language} />}
        </header>
    )
}
