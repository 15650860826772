import React, { useState } from 'react';
import bills from '../js/db_bills';
import TableFilter from '../card-components/TableFilter';

import { HiMiniChevronUpDown as UpDownIcon } from "react-icons/hi2";

export default function ArchiveInvoices() {
    // State variables for each filter
    const [emailFilter, setEmailFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [billNumberFilter, setBillNumberFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState(''); // 'paid' or 'unpaid'

    // Filter the bills based on all the filters
    const filteredBills = bills.filter(bill => {
        const matchesEmail = emailFilter === '' || bill.email.toLowerCase().includes(emailFilter.toLowerCase());
        const matchesName = nameFilter === '' || bill.customerName.toLowerCase().includes(nameFilter.toLowerCase());
        const matchesBillNumber = billNumberFilter === '' || bill.billNumber.toLowerCase().includes(billNumberFilter.toLowerCase());
        const matchesService = serviceFilter === '' || bill.services.join(", ").toLowerCase().includes(serviceFilter.toLowerCase());
        const matchesStatus = statusFilter === '' || (statusFilter === 'paid' ? bill.isPaid : !bill.isPaid);

        return matchesEmail && matchesName && matchesBillNumber && matchesService && matchesStatus;
    });

    // Function to reset all filters
    const resetFilters = () => {
        setEmailFilter('');
        setNameFilter('');
        setBillNumberFilter('');
        setServiceFilter('');
        setStatusFilter('');
    };

    return (
        <section className="card">
            <div className='card-header'>
                <h2>Archive invoices</h2>
                <TableFilter />
            </div>

            <div className="card-body">
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        <input type='checkbox' />
                                        ID <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Email <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Name <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Service <UpDownIcon className='icon' />
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Status <UpDownIcon className='icon' />
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBills.length > 0 ? (
                                filteredBills.map((bill, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span>
                                                <input type='checkbox' />
                                                {bill.billNumber}
                                            </span>
                                        </td>
                                        <td>{bill.email}</td>
                                        <td>{bill.customerName}</td>
                                        <td>{bill.services.join(", ")}</td>
                                        <td>{bill.isPaid ? 'Igen' : 'Nem'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">Nincs találat</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
}
