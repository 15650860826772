import React, { useState, useEffect } from 'react';
import subscribers from '../js/db_subscribers';

import TextEditor from '../../global-components/Texteditor';
import ActionButton from '../../global-components/buttons/ActionButton';

export default function Webmail() {
    const [mailTo, setMailTo] = useState('');

    useEffect(() => {
        const mailtoList = subscribers.map(item => item.email);
        setMailTo(mailtoList.join(","));
    }, []);

    return (
        <section className="card">
            <div className='card-header'>
                <h2>Webmail</h2>
                <TextEditor />
            </div>

            <div className='card-body'>
                <ActionButton text="Send" btnType="send" />
            </div>
        </section>
    );
}
