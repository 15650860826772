import React from 'react';
import { banner } from '../../js/translation';

export default function Banner({ language }) {
  return (
    <div className='banner'>
      <h3>CRM Hub 365</h3>
      <p>{banner[language]}</p>
    </div>
  )
}
