import React from 'react';
import { copyright } from '../../js/translation';

export default function Copyright({ language }) {

  return (
    <div className="copyright">
      <p>
        &copy; {new Date().getFullYear()} {copyright[language]}
      </p>
    </div>
  )
}
