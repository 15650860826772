import React from 'react';
import ActionButton from '../../global-components/buttons/ActionButton';

import { IoClose as CloseIcon } from "react-icons/io5";
import { FaRegAddressBook as AddressBookIcon } from "react-icons/fa";
import { IoLocationSharp as LocationIcon } from "react-icons/io5";
import { FaRegEdit as NoteIcon } from "react-icons/fa";
import { MdPayment as PaymentIcon } from "react-icons/md";
import { BiCommentDetail as CommentIcon } from "react-icons/bi";

export default function AddNewCustomerModal({ isModalOpen, toggleModal }) {
    return (
        <div className='modal add-new-customer-modal' style={isModalOpen ? { visibility: 'unset' } : { visibility: 'hidden' }}>
            <div className='wrapper' style={isModalOpen ? { marginRight: '0px' } : { marginRight: '-800px' }}>
                <div className='modal-header'>
                    <h2>Add Customer</h2>

                    <ul className='icon-set to-right'>
                        <li>
                            <button className='btn bold-btn'>
                                <AddressBookIcon className='icon close-icon' />
                            </button>
                        </li>
                        <li>
                            <button className='btn bold-btn'>
                                <LocationIcon className='icon close-icon' />
                            </button>
                        </li>
                        <li>
                            <button className='btn bold-btn'>
                                <NoteIcon className='icon close-icon' />
                            </button>
                        </li>
                        <li>
                            <button className='btn bold-btn'>
                                <PaymentIcon className='icon close-icon' />
                            </button>
                        </li>
                        <li>
                            <button className='btn bold-btn'>
                                <CommentIcon className='icon close-icon' />
                            </button>
                        </li>
                        <li>
                            <button className='btn bold-btn' onClick={toggleModal}>
                                <CloseIcon className='icon close-icon' />
                            </button>
                        </li>
                    </ul>

                </div>
                <div className='modal-body'>
                    <div className='modal-body-content'>
                        modal-body-content
                    </div>
                </div>
                <div className='modal-footer'>
                    <ActionButton text="Save" btnType="save" />
                </div>
            </div>
        </div>
    )
}
