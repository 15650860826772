import React, { useState } from 'react';
import AdminSideNav from '../global-components/navigations/AdminSideNav';
import Navigation7 from '../global-components/navigations/Navigation7';

import Dashboard from './content/Dashboard';
import CommuniCast from './content/CommuniCast';
import Services from './content/Services';
import InvoiceSnap from './content/InvoiceSnap';
import Profile from './content/Profile';
import Settings from './content/Settings';
import Breadcrumbs from '../global-components/Breadcrumbs';
import Help from './content/Help';
import Customers from './content/Customers';

export default function AdminMain({ page, language }) {
    const [isMenuOpen, setIsMenuOpen] = useState(true);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const pages = {
        dashboard: {
            content: <Dashboard language={language} />
        },
        communicast: {
            content: <CommuniCast language={language} />
        },
        services: {
            content: <Services language={language} />
        },
        invoicesnap: {
            content: <InvoiceSnap language={language} />
        },
        profile: {
            content: <Profile language={language} />
        },
        settings: {
            content: <Settings language={language} />
        },
        help: {
            content: <Help language={language} />
        },
        customers: {
            content: <Customers language={language} />
        },
    };

    return (
        <main>
            <aside>
                <AdminSideNav language={language} isMenuOpen={isMenuOpen} />
            </aside>

            <div className='content-container'>
                <Navigation7 language={language} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                <Breadcrumbs />
                {pages[page].content}
            </div>
        </main>
    )
}
