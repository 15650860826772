import React from 'react';
import { hero } from '../../../js/translation';

export default function Hero({ language }) {
    return (
        <section className='section-container hero'>
            <div className='box hero-box'>
                <p>{hero.p1[language]}</p>
                <h1>{hero.h1[language]}</h1>
                <h2>{hero.h2[language]}</h2>
                <p>{hero.p2[language]}</p>
                <button className='btn marketing-btn'>{hero.button[language]}</button>
            </div>

            <div className='box'>
            </div>
        </section>
    )
}
