import React, { useState, useEffect } from 'react';

import ActionButton from '../../global-components/buttons/ActionButton';

export default function AccountLanguage() {
    const [currentLanguage, setCurrentLanguage] = useState('eng');

    const languageCodes = { eng: 'English', hun: 'Hungarian' };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
        // Save e.target.value to database
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <section className="card ">
            <div className='card-header'>
                <h2>Account language</h2>
            </div>

            <div className='card-body'>
                <form action="" onSubmit={handleSubmit}>

                    <div className="form-group">
                        <label>Language</label>
                        <select value={currentLanguage} onChange={handleLanguageChange}>
                            {Object.entries(languageCodes).map(([code, label]) => (
                                <option key={code} value={code}>{label}</option>
                            ))}
                        </select>
                    </div>

                    <ActionButton text="Save" btnType="save" />
                </form>
            </div>
        </section>
    );
}
