import React from 'react';
import ArchiveNewsletters from "../cards/ArchiveNewsletters";
import ManageSubscribers from "../cards/ManageSubscribers";
import SendNewsletterFromOwnDevice from "../cards/SendNewsletterFromOwnDevice";
import Webmail from '../cards/Webmail';
import AddNewSubscriber from '../cards/AddNewSubscriber';

export default function CommuniCast({ language }) {
    return (
        <div className='content'>
            <SendNewsletterFromOwnDevice />
            <Webmail /> {/*This one indicates the Warning*/}
            <ManageSubscribers />
            <ArchiveNewsletters />
        </div>
    )
}



