import React, { useState, useEffect } from 'react';

import admin from '../js/db_admin';
import ActionButton from '../../global-components/buttons/ActionButton';

export default function Name() {

    const [firstName, setFirstName] = useState(admin.adataim.utonev);
    const [surname, setSurname] = useState(admin.adataim.vezeteknev);

    const [firstNameInput, setFirstNameInput] = useState("");
    const [surnameInput, setSurnameInput] = useState("");

    const handleNameChange = (e, nameType) => {
        if (nameType === "first name") {
            setFirstNameInput(e.target.value);
        } else {
            setSurnameInput(e.target.value);
        }
    };

    const handleSubmit = (e,) => {
        e.preventDefault();

        const sanitizedFirstName = firstNameInput.trim().replace(/\s+/g, ' ').toLocaleLowerCase();
        const sanitizedSurname = surnameInput.trim().replace(/\s+/g, ' ').toLocaleLowerCase();

        if (sanitizedFirstName) {
            setFirstName(sanitizedFirstName);
            setFirstNameInput('');
        }
        if (sanitizedSurname) {
            setSurname(sanitizedSurname);
            setSurnameInput('');
        }
    };

    return (
        <section className="card ">
            <div className='card-header'>
                <h2>Name</h2>
            </div>

            <div className='card-body'>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name</label>
                        <span className='name-span'>{surname} {firstName}</span>
                    </div>

                    <div className="form-group">
                        <label>New surname</label>
                        <input value={surnameInput} type="text" onChange={(e) => handleNameChange(e, "surname")} />
                    </div>

                    <div className="form-group">
                        <label>New first name</label>
                        <input value={firstNameInput} type="text" onChange={(e) => handleNameChange(e, "first name")} />
                    </div>

                    <ActionButton text="Save" btnType="save" />
                </form>
            </div>
        </section>
    );
}
