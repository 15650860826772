import React from 'react';
import Home from './content/Home';
import Login from './content/Login';
import Registration from './content/Registration';
import About from './content/About';

export default function WebsiteMain({ page, language, loginAs, setLoginAs }) {
    const pages = {
        home: {
            content: <Home language={language} />
        },
        login: {
            content: <Login language={language} loginAs={loginAs} setLoginAs={setLoginAs} />
        },
        registration: {
            content: <Registration language={language} loginAs={loginAs} setLoginAs={setLoginAs} />
        },
        about: {
            content: <h1 language={language} >about</h1>
        },
        "plans-and-pricing": {
            content: <h1 language={language} >plans-and-pricing</h1>
        },
        contact: {
            content: <h1 language={language} >contact</h1>
        },
        support: {
            content: <h1 language={language} >support</h1>
        },
        privacy: {
            content: <h1 language={language} >privacy</h1>
        },
        "terms-and-conditions": {
            content: <h1 language={language} >terms-and-conditions</h1>
        },
    };

    return (
        <main>
            {/*<aside></aside>*/}
            <div className='content-container'>
                {pages[page].content}
            </div>
        </main>
    )
}
